import "./style.scss";
import { Network } from "@capacitor/network";
import { ModalStatus, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import {
  IonButton,
  IonContent,
  IonImg,
  IonItemGroup,
  IonPopover,
  IonSpinner,
  IonText,
} from "@ionic/react";
import { FileExplorer } from "@src/app/components/uploadFile/fileExplorer";
import { SelectedFile } from "@src/app/shiftSignature/timecard/model";
import { isImage } from "@src/app/utils/isImage";
import { useToast } from "@src/appV2/lib";
import { Shift } from "@src/lib/interface";
import { useState } from "react";

import { enterAnimation, leaveAnimation } from "../../rating/reviewPrompt/popoverAnimation";
import { ShiftDetailsAlerts } from "../shiftDetails/alerts";

interface TimeSheetSubmissionProps {
  shift: Shift;
  onSubmit: (file: SelectedFile | null) => void;
  isShiftTimeEditOpen: boolean;
  showFileExplorer: boolean;
  shiftDetailsAlerts: ShiftDetailsAlerts;
}

export function TimeSheetSubmission(props: TimeSheetSubmissionProps) {
  const { shift, onSubmit, isShiftTimeEditOpen, showFileExplorer, shiftDetailsAlerts } = props;

  const uploadTimesheetModalState = useModalState(
    showFileExplorer ? ModalStatus.OPEN : ModalStatus.CLOSED
  );

  const [selectedFile, setSelectedFile] = useState<SelectedFile | null>(null);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { showErrorToast } = useToast();

  const onUpload = (SelectedFile: SelectedFile) => {
    if (isImage(SelectedFile.type)) {
      setSelectedFile(SelectedFile);
      uploadTimesheetModalState.closeModal();
    } else {
      showErrorToast("File format not supported");
    }
  };
  const submitFile = async () => {
    //network check
    const status = await Network.getStatus();
    if (!status.connected) {
      shiftDetailsAlerts.alertNoNetworkConnection({ isEdit: true });
      return;
    }
    setDisableSubmit(true);
    onSubmit(selectedFile);
  };

  return (
    <IonContent>
      <div className="timesheet-submission">
        <div>
          <div className="timesheet-summary-text">Confirm your times match your timesheet</div>
        </div>
        {selectedFile ? (
          <IonImg
            className="timesheet-image"
            src={selectedFile["file"]}
            style={{ maxHeight: window.innerHeight - 115 }}
          />
        ) : (
          <div className="empty-timesheet-image"></div>
        )}
        {uploadTimesheetModalState.modalIsOpen && shift && (
          <IonPopover
            isOpen
            showBackdrop
            backdropDismiss={false}
            enterAnimation={enterAnimation}
            leaveAnimation={leaveAnimation}
            cssClass="upload-prompt"
          >
            <div id="overlay-section" className="timesheet-upload">
              <IonItemGroup className="upload-time-group">
                <FileExplorer onFileSelected={onUpload} />
                <div
                  onClick={() => uploadTimesheetModalState.closeModal()}
                  className="ion-text-center ion-margin file-upload-cancel-btn"
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => {}}
                >
                  <IonText>Cancel</IonText>
                </div>
              </IonItemGroup>
            </div>
          </IonPopover>
        )}
        <div className="timesheet-submit-buttons">
          <IonButton
            color="primary"
            shape="round"
            className="timesheet-upload-button"
            onClick={() => uploadTimesheetModalState.openModal()}
          >
            {isDefined(selectedFile) ? "Retake Picture" : "Take Picture"}
          </IonButton>
          <IonButton
            color="primary"
            shape="round"
            className="timesheet-submit-button"
            onClick={submitFile}
            disabled={!isDefined(selectedFile) || isShiftTimeEditOpen || disableSubmit}
          >
            <IonSpinner hidden={!disableSubmit} slot="start" name="crescent" />
            {disableSubmit ? "Submitting" : "Submit"}
          </IonButton>
        </div>
      </div>
    </IonContent>
  );
}
